import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'
import { AutoCompleteProps } from '@data-c/hooks'
import useGrupoProduto, {
  GrupoProdutoModel,
} from 'hooks/queries/useGrupoProduto'

export default function AutoCompleteGrupoProduto(
  props: AutoCompleteProps<GrupoProdutoModel>,
) {
  const { onChange, value, autoFocus } = props
  const { useQueryObterGrupoProduto } = useGrupoProduto()
  const { data, isLoading } = useQueryObterGrupoProduto()

  return (
    <Autocomplete
      value={value}
      disablePortal
      options={data?.data || []}
      onChange={onChange}
      getOptionLabel={(grupoProduto) => {
        return `${grupoProduto.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          value={value}
          label="Grupo de Produto"
          autoFocus={autoFocus}
        />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
