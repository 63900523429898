import {
  BottomNavigationActionProps,
  BottomNavigationAction as MuiBottomNavigationAction,
} from '@mui/material'

export default function BottomNavigationAction(
  props: BottomNavigationActionProps,
) {
  return (
    <MuiBottomNavigationAction
      sx={{ padding: '0 8px', minWidth: '64px' }}
      {...props}
    />
  )
}
