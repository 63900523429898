import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import Layout from 'pages/Layout'
import Login from 'pages/Login'
import Operacao from 'pages/NF/Operacao'
import NFProdutos from 'pages/NF/Produtos'
import NFProduto from 'pages/NF/Produto'
import NFClientes from 'pages/NF/Clientes'
import FormaPagamento from 'pages/NF/FormaPagamento'
import ConfigurarPagamento from 'pages/NF/ConfigurarPagamento'
import DetalhamentoNF from 'pages/NF/DetalhamentoNF'
import Empresa from 'pages/NF/Empresa'
import NFFechada from 'pages/NF/NFFechada'
import NFTransmitida from 'pages/NF/NFTransmitida'
import Vendas from 'pages/Vendas'
import CadastroCliente from 'pages/CadastroCliente'
import Clientes from 'pages/Clientes'
import CancelamentoNF from 'pages/NF/CancelamentoNF'
import NFCancelada from 'pages/NF/NFCancelada'
import VisualizarDanfe from 'pages/NF/VisualizarDanfe'
import VisualizarXML from 'pages/NF/VisualizarXML'
import EnvioEmailNF from 'pages/NF/EnvioEmailNF'
import EmailEnviado from 'pages/NF/EmailEnviado'
import Perfil from 'pages/Perfil'
import SelecaoLicenca from 'pages/SelecaoLicenca'
import AlteracaoSenha from 'pages/AlteracaoSenha'
import GeracaoDeBoleto from 'pages/NF/GeracaoDeBoleto'
import Empresas from 'pages/Empresas'
import Boletos from 'pages/Boletos'
import Produtos from 'pages/Produtos'

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/login" element={<Login />} />
        <Route index path="/" element={<Login />} />

        <Route
          path="/perfil"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="/perfil" element={<Perfil />} />
          <Route path="/perfil/selecao-licenca" element={<SelecaoLicenca />} />
          <Route path="/perfil/alterar-senha" element={<AlteracaoSenha />} />
        </Route>

        <Route
          path="/selecao-licenca"
          element={
            <Layout>
              <SelecaoLicenca />
            </Layout>
          }
        />
        <Route
          path="/vendas"
          element={
            <Layout>
              <Vendas />
            </Layout>
          }
        />
        <Route
          path="/clientes"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="lista" element={<Clientes />} />
          <Route path="cliente/:id" element={<CadastroCliente />} />
          <Route path="cadastro-clientes" element={<CadastroCliente />} />
        </Route>

        <Route
          path="/produtos"
          element={
            <Layout>
              <Produtos />
            </Layout>
          }
        />

        <Route
          path="/empresas"
          element={
            <Layout>
              <Empresas />
            </Layout>
          }
        />

        <Route
          path="/boletos"
          element={
            <Layout>
              <Boletos />
            </Layout>
          }
        />

        <Route
          path="/NF"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="empresa" element={<Empresa />} />
          <Route path="operacao" element={<Operacao />} />
          <Route path="clientes" element={<NFClientes />} />
          <Route
            path="clientes/cadastro-cliente"
            element={<CadastroCliente />}
          />
          <Route path="/NF/:id/produtos" element={<NFProdutos />} />
          <Route
            path="/NF/:nfId/produtos/produto/:produtoId"
            element={<NFProduto />}
          />
          <Route
            path="/NF/:nfId/forma-de-pagamento"
            element={<FormaPagamento />}
          />
          <Route
            path="/NF/:nfId/configurar-pagamento"
            element={<ConfigurarPagamento />}
          />
          <Route
            path="/NF/:nfId/detalhamento-nf"
            element={<DetalhamentoNF />}
          />
          <Route path="/NF/:nfId/nf-fechada" element={<NFFechada />} />
          <Route path="/NF/:nfId/nf-transmitida" element={<NFTransmitida />} />
          <Route
            path="/NF/:nfId/envio-nf-por-email"
            element={<EnvioEmailNF />}
          />
          <Route path="/NF/:nfId/email-enviado" element={<EmailEnviado />} />
          <Route
            path="/NF/:nfId/visualizar-danfe"
            element={<VisualizarDanfe />}
          />
          <Route path="/NF/:nfId/visualizar-xml" element={<VisualizarXML />} />
          <Route
            path="/NF/:nfId/geracao-de-boleto"
            element={<GeracaoDeBoleto />}
          />
          <Route
            path="/NF/:nfId/justificativa-cancelamento"
            element={<CancelamentoNF />}
          />
          <Route path="/NF/:nfId/nf-cancelada" element={<NFCancelada />} />
        </Route>

        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </BrowserRouter>
  )
}
