import { Box, useTheme } from '@mui/material'
import { ReactNode } from 'react'

interface CardContainerProps {
  children: ReactNode
}

export default function CardContainer(props: CardContainerProps) {
  const { children } = props

  const theme = useTheme()

  return (
    <Box
      sx={{
        border: `solid 1px ${theme.palette.grey[300]}`,
        minHeight: '158px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.paper,
        padding: 2,
        marginTop: 1,
      }}
    >
      {children}
    </Box>
  )
}
