import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import { FilterProvider } from '@data-c/providers'

import { FilterControl } from 'components/FilterContainer'
import HeaderContainer from 'components/Container/components/HeaderContainer'
import Container from 'components/Container'
import PageTitle from 'components/PageTitle'

import { produtoConsultaFilters } from 'hooks/queries/useProduto'

import Filter, { formatarFilterLabels } from './components/Filter'
import Search from './components/Search'
import Lista from './components/Lista'

export default function Produtos() {
  const navigate = useNavigate()

  return (
    <Container fullHeight={false}>
      <FilterProvider filterValues={produtoConsultaFilters}>
        <HeaderContainer
          containerSx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Stack flexDirection="row" justifyContent="space-between">
            <PageTitle
              title="Produtos"
              disablePadding
              onBack={() => navigate('/vendas')}
            />
            <Filter />
          </Stack>

          <Search />

          <FilterControl
            renderLabel={formatarFilterLabels}
            initialFilters={produtoConsultaFilters}
          />
        </HeaderContainer>

        <Lista />
      </FilterProvider>
    </Container>
  )
}
