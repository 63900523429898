import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography } from '@mui/material'
import {
  PaymentsOutlined as PaymentsIcon,
  List as ListIcon,
} from '@mui/icons-material'

import { formatCurrency } from '@data-c/hooks'
import { useNFContext } from 'contexts/NFContext/NFContext'
import { NFModel } from 'hooks/queries/useNF'

import FloatFooterCurrency from 'components/FloatFooter/components/FloatFooterCurrency'
import ButtonContainer from 'components/ButtonContainer'
import FloatDrawer from 'components/FloatDrawer'
import Button from 'components/Button'

interface FooterProps {
  nf?: NFModel
}

export default function Footer({ nf }: FooterProps) {
  const { isLoading } = useNFContext()
  const navigate = useNavigate()

  const [isOpenDrawer, setOpenDrawer] = useState(false)

  const existeNFPagamento = (nf?.nfPagamentos?.length || 0) > 0
  const itensAdicionados = nf?.nfItems?.length
  const totalNFFormatado = formatCurrency(nf?.totalNF || 0)
  const flexSingularPluralText =
    itensAdicionados && itensAdicionados > 1
      ? ' produtos adicionados'
      : ' produto adicionado'

  function handleNavigateFormaPagamento() {
    if (isLoading) return
    navigate(`/NF/${nf?.id}/forma-de-pagamento`)
  }

  function handleNavigateDetalhamentoNF() {
    if (isLoading) return
    navigate(`/NF/${nf?.id}/detalhamento-nf`)
  }

  return (
    <FloatDrawer
      isOpen={isOpenDrawer}
      onOpenDrawer={() => setOpenDrawer(!isOpenDrawer)}
      headerContent={
        <>
          <Typography variant="body1">
            {itensAdicionados}
            {flexSingularPluralText}
          </Typography>
          <FloatFooterCurrency title="Total" price={totalNFFormatado} />
        </>
      }
      customSx={{ padding: 1.5 }}
    >
      <ButtonContainer margin="12px 0">
        <Button
          variant={existeNFPagamento ? 'outlined' : 'contained'}
          fullWidth
          startIcon={<PaymentsIcon />}
          disabled={isLoading}
          onClick={handleNavigateFormaPagamento}
        >
          Forma de pagamento
        </Button>

        {existeNFPagamento && (
          <Button
            variant="contained"
            fullWidth
            startIcon={<ListIcon />}
            disabled={isLoading}
            onClick={handleNavigateDetalhamentoNF}
          >
            Detalhes da venda
          </Button>
        )}
      </ButtonContainer>
    </FloatDrawer>
  )
}
