import { useNavigate } from 'react-router-dom'
import { Avatar } from '@mui/material'

export default function PerfilAvatar() {
  const navigate = useNavigate()

  return (
    <Avatar
      alt="photoURL"
      sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
      onClick={() => navigate('/perfil')}
    />
  )
}
