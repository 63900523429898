import { ReactNode, useEffect, useState } from 'react'
import { Paper, styled, SxProps } from '@mui/material'
import Container from 'components/Container'

interface HeaderContainerProps {
  children: ReactNode
  paperElevation?: boolean
  disableFixed?: boolean
  customSx?: SxProps
  containerSx?: SxProps
}

const StyledHeader = styled(Paper)<{ isFixed: boolean }>(({ isFixed }) => ({
  position: isFixed ? 'fixed' : 'relative',
  top: 0,
  width: '100%',
  transition: 'transform 0.3s ease, opacity 0.3s ease, background-color 0.3s',
  transform: isFixed ? 'translateY(0)' : 'translateY(-20px)',
  marginTop: isFixed ? 0 : '20px',
  marginBottom: isFixed ? 0 : '-20px',
  opacity: isFixed ? 1 : 0.95,
  zIndex: 100,
}))

export default function HeaderContainer(props: HeaderContainerProps) {
  const {
    children,
    paperElevation = false,
    disableFixed = false,
    customSx,
    containerSx,
  } = props

  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100 && !disableFixed) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [disableFixed])

  return (
    <StyledHeader
      isFixed={isScrolled}
      elevation={paperElevation ? 1 : 0}
      sx={{
        paddingY: 2,
        paddingX: 1,
        // position: disableFixed ? 'relative' : 'fixed',
        // position: isScrolled ? 'fixed' : 'relative',
        // top: 0,
        // left: 0,
        // right: 0,
        // zIndex: 100,
        ...customSx,
      }}
    >
      <Container overflow="visible" fullHeight={false} customSx={containerSx}>
        {children}
      </Container>
    </StyledHeader>
  )
}
