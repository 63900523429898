import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { HttpResponseInterface } from '@data-c/hooks'
import { FilterOptions } from '@data-c/providers'
import { useNotifications } from '@data-c/hooks'

import QueryParamsInterface from 'interfaces/QueryParamsInterface'
import api from 'services/api'

export interface GrupoProdutoModel {
  id: string
  nome: string
}

export interface GrupoProdutoFilters {
  query: FilterOptions<string>
}

export interface GrupoProdutoFilters1 {
  query: string
}

export const filtersGrupoProduto: GrupoProdutoFilters = {
  query: {
    label: 'Nome',
  },
}

async function obterGrupoProduto(
  params?: QueryParamsInterface,
): Promise<HttpResponseInterface<GrupoProdutoModel>> {
  let paginationProps = params?.pagination

  if (!paginationProps) {
    paginationProps = {
      page: 1,
      pageSize: 20,
    }
  }

  const { page, pageSize } = paginationProps

  const response = await api.get(
    `ProdutoGrupo/Pesquisar/${params?.query || ''}`,
    {
      headers: {
        'Resposta-Compactada': 'Nunca',
        'DC-Page': page,
        'DC-PageSize': pageSize,
      },
    },
  )

  return response?.data
}

export function useQueryObterGrupoProduto(params?: QueryParamsInterface) {
  return useQuery<HttpResponseInterface<GrupoProdutoModel>, AxiosError>(
    ['GRUPO_PRODUTO', params],
    () => obterGrupoProduto(params),
  )
}

export function useObterGrupoProdutoPorId() {
  const [isLoading, setLoading] = useState(false)

  async function obterGrupoProdutoPorId(
    id: string,
  ): Promise<GrupoProdutoModel | null> {
    const notifications = useNotifications()

    try {
      setLoading(true)
      const response = await api.get(`ProdutoGrupo/${id}`)
      if (response?.data?.data) {
        return response?.data?.data
      }
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }

    return null
  }

  return { obterGrupoProdutoPorId, isLoading }
}

export default function useGrupoProduto() {
  return {
    useQueryObterGrupoProduto,
    useObterGrupoProdutoPorId,
  }
}
