import { ReactNode } from 'react'
import {
  IconButton,
  Paper,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { ArrowBack, Close } from '@mui/icons-material'
import Container from 'components/Container'

interface PageTitleProps {
  title: string
  paperElevation?: boolean
  fixed?: boolean
  children?: ReactNode
  disablePadding?: boolean
  onBack?: () => void
  onClose?: () => void
}

export default function PageTitle(props: PageTitleProps) {
  const {
    title,
    paperElevation,
    fixed = false,
    children,
    disablePadding = false,
    onBack,
    onClose,
  } = props

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Paper
      elevation={paperElevation ? 1 : 0}
      sx={{
        position: fixed ? 'fixed' : 'relative',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        display: 'flex',
        flexDirection: isSmall || onClose ? 'column' : 'row',
        // justifyContent: 'space-between',
        justifyContent: 'center',
        alignItems: isSmall ? 'center' : 'flex-end',
        gap: 2,
        paddingY: disablePadding ? 0 : 2,
        paddingX: disablePadding ? 0 : 1,
      }}
    >
      <Stack width="100%" direction="row" sx={{ alignItems: 'center' }}>
        {onBack && (
          <IconButton sx={{ mr: 1 }} onClick={onBack}>
            <ArrowBack />
          </IconButton>
        )}

        <Typography component="h1" variant="h2" sx={{ fontWeight: '700' }}>
          {title}
        </Typography>

        {onClose && (
          <IconButton sx={{ marginLeft: 'auto' }} onClick={onClose}>
            <Close fontSize="medium" />
          </IconButton>
        )}
      </Stack>

      {children && (
        <Container overflow="visible" fullHeight={false}>
          {children}
        </Container>
      )}
    </Paper>
  )
}
