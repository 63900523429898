import { memo } from 'react'
import { Paper, useTheme } from '@mui/material'

import { boletoStatusMappingColors } from 'components/Inputs/DropDownBoletoStatus'
import { BoletoDetalhadoModel } from 'hooks/queries/useBoleto'

import ContentInfo from './components/ContentInfo'
import Header from './components/Header'
import Footer from './components/Footer'

export interface CardBoletoModel extends BoletoDetalhadoModel {
  dataAlteracaoFormatada: string
  dataEmissaoFormatada: string
  dataVencimentoFormatada: string
  dataPagamentoFormatada: string
  valorTituloFormatado: string
  valorAbatimentoFormatado: string
  valorAtualFormatado: string
  multaValorFormatado: string
  descontoValorFormatado: string
  valorPagoFormatado: string
  valorTarifaFormatado: string
  dataTarifaFormatada: string
  dataCancelamentoFormatada: string
  dataRegistroFormatada: string
  dataMovimentoFormatada: string
  dataCreditoFormatada: string
  dataAntecipacaoFormatada: string
  dataAntecipacaoCancelamentoFormatada: string
}

export interface CardBoletoComponentProps {
  data: CardBoletoModel
  isSelected: boolean
  onSelect: (dataSelecionada: BoletoDetalhadoModel) => void
}

function CardBoletoComponent(props: CardBoletoComponentProps) {
  const { data, isSelected, onSelect } = props
  const {
    status,
    statusDesc,
    dataAlteracaoFormatada,
    valorTituloFormatado,
    dataVencimentoFormatada,
  } = data

  const theme = useTheme()

  return (
    <Paper
      elevation={0}
      sx={{
        border: `solid 1px ${theme.palette.grey[300]}`,
        marginTop: 1,
        boxShadow: isSelected ? '0px 0px 10px 0px rgba(0,0,0,0.25)' : '',
        scale: isSelected ? '0.96' : 'initial',
        transition: 'linear 150ms',
        position: 'relative',
        ':after': {
          content: '""',
          position: 'absolute',
          width: '4px',
          height: '100%',
          top: 0,
          left: 0,
          bottom: 0,
          backgroundColor: boletoStatusMappingColors[status],
        },
      }}
    >
      <Header
        dataAlteracaoFormatada={dataAlteracaoFormatada}
        statusDesc={statusDesc}
        status={status}
        isSelected={isSelected}
        onSelect={() => onSelect(data)}
      />
      <ContentInfo {...data} />
      <Footer
        valorTituloFormatado={valorTituloFormatado}
        dataVencimentoFormatada={dataVencimentoFormatada}
      />
    </Paper>
  )
}

export const CardBoleto = memo(CardBoletoComponent)
