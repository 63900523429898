import { useState } from 'react'
import { Stack } from '@mui/material'
import { FilterProvider } from '@data-c/providers'

import { FilterControl } from 'components/FilterContainer'
import HeaderContainer from 'components/Container/components/HeaderContainer'
import AutoCompleteClientes from 'components/AutoCompleteClientes'
import ButtonContainer from 'components/ButtonContainer'
import Container from 'components/Container'
import PageTitle from 'components/PageTitle'

import {
  nfConsultafilters,
  NFConsultaFiltersOptions,
} from 'hooks/queries/useNF'

import Filter, { formatarFilterLabels } from './components/Filter'
import Lista from './components/Lista'
import PerfilAvatar from 'components/PerfilAvatar'

export default function Vendas() {
  const [appliedFilters, setFilters] = useState<NFConsultaFiltersOptions>(
    {} as NFConsultaFiltersOptions,
  )

  return (
    <Container fullHeight={false}>
      <FilterProvider filterValues={nfConsultafilters}>
        <HeaderContainer
          containerSx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
        >
          <Stack flexDirection="row" justifyContent="space-between">
            <PageTitle title="Vendas" disablePadding />
            <ButtonContainer flexDirection="row">
              <Filter onApplyFilters={(filters) => setFilters(filters)} />
              <PerfilAvatar />
            </ButtonContainer>
          </Stack>

          <AutoCompleteClientes
            onChange={(_, value) => {
              setFilters((prevState) => ({
                ...prevState,
                destinatario: value,
              }))
            }}
          />
          <FilterControl
            renderLabel={formatarFilterLabels}
            initialFilters={nfConsultafilters}
            disableds={[
              'dataInicio',
              'dataFim',
              'nfConsultaTipoData',
              'tipoTransmissao',
            ]}
          />
        </HeaderContainer>

        <Lista filters={appliedFilters} />
      </FilterProvider>
    </Container>
  )
}
