import { useMemo } from 'react'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { useFilterData } from '@data-c/providers'
import { formatCurrency } from '@data-c/hooks'

import ContentContainer from 'components/Container/components/ContentContainer'
import SelectableToolbar from 'components/SelectableToolbar'
import EmptyData from 'components/EmptyData'

import { useSelectableCardContext } from 'contexts/SelectableCardContext'

import useBoleto, {
  BoletoDetalhadoModel,
  BoletoFiltersOptions,
} from 'hooks/queries/useBoleto'
import useDateFormat from 'hooks/queries/useDateFormat'

import DataListProps from 'interfaces/DataListProps'

import { CardBoleto } from './components/CardBoletos'
import Actions from './components/Actions'

import { formatarFiltros } from '../Filter'

interface ListaProps extends DataListProps<BoletoFiltersOptions> {}

export default function Lista(props: ListaProps) {
  const { filters } = props

  const {
    itensSelecionados,
    setItemSelecionado,
    onRemoverTodosIntesSelecionados,
  } = useSelectableCardContext<BoletoDetalhadoModel>()

  const { formatDateToDiaMesAnoHoraMinuto, formatDateToDiaMesAno } =
    useDateFormat()
  const { searchId } = useFilterData()

  const { useQueryObterBoletos } = useBoleto()
  const { data, isLoading, isFetching, error } = useQueryObterBoletos({
    queryParams: formatarFiltros(filters),
    requestId: searchId,
  })

  const dataFormatada = useMemo(() => {
    return (
      data?.data?.map((item) => {
        return {
          ...item,
          dataAlteracaoFormatada: formatDateToDiaMesAnoHoraMinuto(
            item?.dataAlteracao || '',
          ),
          dataEmissaoFormatada: formatDateToDiaMesAno(item?.dataEmissao),
          dataVencimentoFormatada: formatDateToDiaMesAno(
            item?.dataVencimento || '',
          ),
          dataPagamentoFormatada: formatDateToDiaMesAno(
            item?.dataPagamento || '',
          ),
          pagadorCNPJCPF: formatToCPFOrCNPJ(item?.pagadorCNPJCPF || ''),
          valorTituloFormatado: formatCurrency(item?.valorTitulo),
          valorAbatimentoFormatado: formatCurrency(item?.valorAbatimento),
          valorAtualFormatado: formatCurrency(item?.valorAtual || ''),
          multaValorFormatado: formatCurrency(item?.multaValor || ''),
          descontoValorFormatado: formatCurrency(item?.descontoValor),
          valorPagoFormatado: formatCurrency(item?.valorPago || ''),
          valorTarifaFormatado: formatCurrency(item?.valorTarifa || ''),
          dataTarifaFormatada: formatDateToDiaMesAno(item?.dataTarifa || ''),
          dataCancelamentoFormatada: formatDateToDiaMesAno(
            item?.dataCancelamento || '',
          ),
          dataRegistroFormatada: formatDateToDiaMesAno(
            item?.dataRegistro || '',
          ),
          dataMovimentoFormatada: formatDateToDiaMesAno(
            item?.dataMovimento || '',
          ),
          dataCreditoFormatada: formatDateToDiaMesAno(item?.dataCredito || ''),
          dataAntecipacaoFormatada: formatDateToDiaMesAno(
            item?.dataAntecipacao || '',
          ),
          dataAntecipacaoCancelamentoFormatada: formatDateToDiaMesAno(
            item?.dataAntecipacaoCancelamento || '',
          ),
        }
      }) || []
    )
  }, [data?.data])

  return (
    <ContentContainer
      isLoading={isLoading || isFetching}
      error={error}
      disablePadding
      customSx={{ marginBottom: '8px' }}
    >
      <SelectableToolbar
        countQuantidade={itensSelecionados.length}
        onClear={() => onRemoverTodosIntesSelecionados()}
      >
        <Actions boletos={data?.data || []} />
      </SelectableToolbar>

      {dataFormatada.length > 0 ? (
        dataFormatada.map((boleto) => (
          <CardBoleto
            key={boleto.id}
            data={boleto}
            isSelected={itensSelecionados.some(
              (item) => item?.id === boleto?.id,
            )}
            onSelect={setItemSelecionado}
          />
        ))
      ) : (
        <EmptyData
          description="Nenhum boleto foi encontrado!"
          instructionText="Você pode definir novos filtros para visualizar os boletos."
        />
      )}
    </ContentContainer>
  )
}
