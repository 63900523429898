import { FilterOptions } from '@data-c/providers'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import ErrorInterface from 'interfaces/ErrorInterface'

import api from 'services/api'
import { GrupoProdutoModel } from './useGrupoProduto'
import HttpRequestInterface from 'interfaces/HttpRequestInterface'

export interface ProdutoModel {
  id?: string
  codigo?: string | null
  codigoEAN?: string | null
  codigoEANTrib?: string | null
  nome: string
  unidadeComercial?: string | null
  unidadeTrib?: string | null
  estoque?: number | null
  precoUnitario?: number | null
  precoUnitarioProntaEntrega?: number | null
  produtoGrupoId?: string | null
  produtoGrupoNome: string
  produtoGTId?: string | null
  produtoGTNome?: string | null
  ativo: boolean
  codigoExterno?: string | null
  ncmId?: string | null
  cestId?: string | null
  bloqueiaQuantidadeFracionada: boolean
}

export interface ProdutoConsultaModel {
  id?: string
  codigo?: string | null
  codigoEAN?: string | null
  codigoEANTrib?: string | null
  nome?: string | null
  unidadeComercial?: string | null
  unidadeTrib?: string | null
  estoque?: number | null
  precoUnitario?: number | null
  precoUnitarioProntaEntrega?: number | null
  produtoGrupoId?: string | null
  produtoGrupoNome?: string | null
  produtoGTId?: string | null
  produtoGTNome?: string | null
  ativo: boolean
  codigoExterno?: string | null
  ncmId?: string | null
  cestId?: string | null
  bloqueiaQuantidadeFracionada: boolean
  produzirKit: boolean
}

export interface ProdutoConsultaFilltersOptions {
  nome?: FilterOptions<string | null>
  produtoGrupoId?: FilterOptions<string | null>
  produtoGrupo: FilterOptions<GrupoProdutoModel | null>
  // somenteAtivos: FilterOptions<boolean>
  // tipoEstoque: FilterOptions<number>
  // estoque: FilterOptions<number>
}

export interface ProdutoConsultaFilters {
  nome?: string | null
  produtoGrupo?: GrupoProdutoModel | null
  produtoGrupoId?: string | null
  // somenteAtivos: boolean
  // tipoEstoque: number
  // estoque: number
}

export const produtoConsultaFilters: ProdutoConsultaFilltersOptions = {
  nome: {
    value: '',
    label: 'Nome',
  },
  produtoGrupo: {
    value: null,
    label: 'Grupo',
  },
  // somenteAtivos: {
  //   label: 'Ativo',
  //   value: false,
  // },
  // tipoEstoque: {
  //   value: 0,
  //   label: 'Tipo Estóque',
  // },
  // estoque: {
  //   value: 0,
  //   label: 'Estóque',
  // },
}

export async function obterProdutos(query: string) {
  const response = await api.get(`ProdutoConsulta/Pesquisar/${true}/${query}`)

  return response.data
}

export async function obterProdutoConsullta(
  filters?: ProdutoConsultaFilters | null,
) {
  if (!filters) return { data: [] }

  delete filters.produtoGrupo

  const response = await api.post('ProdutoConsulta/PesquisarValidacao', filters)
  return response.data
}

export async function obterProdutoPorId(id: string): Promise<ProdutoModel> {
  const response = await api.get<{ data: ProdutoModel }>(
    'ProdutoConsulta/ObterPorId',
    {
      params: { id },
    },
  )

  return response.data.data
}

export function useQueryObterProdutos(query: string) {
  return useQuery<
    HttpResponseInterface<ProdutoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['PRODUTOS', query], () => {
    return obterProdutos(query)
  })
}

export function useQueryObterProdutoConsulta(
  data?: HttpRequestInterface<ProdutoConsultaFilters | null>,
) {
  return useQuery<
    HttpResponseInterface<ProdutoConsultaModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['PRODUTO_CONSULTA', data?.queryParams, data?.requestId], () => {
    return obterProdutoConsullta(data?.queryParams)
  })
}

export function useQueryObterProdutoPorId(id: string) {
  return useQuery<ProdutoModel, AxiosError>(['PRODUTO', id], () => {
    return obterProdutoPorId(id)
  })
}

export default function useProduto() {
  return {
    useQueryObterProdutos,
    useQueryObterProdutoConsulta,
    useQueryObterProdutoPorId,
  }
}
