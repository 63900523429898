import { useFilterApi, useFilterData } from '@data-c/providers'
import SearchTextField from 'components/SearchTextField'

export default function Search() {
  const { changeFilterValue, applyFilterValues } = useFilterApi()
  const { appliedValues } = useFilterData()

  return (
    <SearchTextField
      value={appliedValues?.nome || ''}
      placeholder="Pesquisar produto"
      autoFocus={false}
      onSearch={(query) => {
        changeFilterValue('nome', query)
        applyFilterValues()
      }}
    />
  )
}
