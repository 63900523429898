import { useMemo } from 'react'
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { ClienteModel } from 'hooks/queries/useCliente'
import useCapitalize from 'hooks/useCapitalize'

interface ClientesCardInfoProps {
  cliente: ClienteModel
  onClick: (cliente: ClienteModel) => void
}

export default function ClientesCardInfo(props: ClientesCardInfoProps) {
  const { cliente, onClick } = props

  const theme = useTheme()
  const { capitalizeFirstLetter } = useCapitalize()

  const dataFormatada = useMemo(() => {
    return {
      documentoIdFormatado: formatToCPFOrCNPJ(cliente.documentoId),
      enderecoFormatado: capitalizeFirstLetter(
        `${cliente.bairro}, ${cliente.cidadeNome}`,
      ),
    }
  }, [cliente])

  return (
    <Paper
      elevation={0}
      onClick={() => onClick(cliente)}
      sx={{
        marginTop: 1,
        border: `solid 1px ${theme.palette.grey[300]}`,
        ':hover': { cursor: 'pointer' },
        position: 'relative',
        ':after': {
          content: '""',
          position: 'absolute',
          width: '4px',
          height: '100%',
          backgroundColor: cliente.ativo ? '' : theme.palette.error.main,
          top: 0,
          left: 0,
          bottom: 0,
        },
      }}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 1,
          paddingX: 2,
          borderBottom: `solid 1px ${theme.palette.grey[300]}`,
          textTransform: 'uppercase',
        }}
      >
        <Typography variant="body1" fontWeight="bold">
          {cliente.codigo} - {cliente.nome}
        </Typography>
      </Box>

      <Stack
        sx={{
          paddingTop: 1,
          paddingBottom: 2,
          paddingX: 2,
          gap: 0.2,
          borderBottom: `solid 1px ${theme.palette.grey[300]}`,
        }}
      >
        <Typography variant="body2">{cliente.nomeFantasia}</Typography>
        <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
          {dataFormatada.documentoIdFormatado}
        </Typography>
        <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
          {dataFormatada.enderecoFormatado} - {cliente.cidadeUF}
        </Typography>
      </Stack>
    </Paper>
  )
}
