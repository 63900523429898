import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { Search } from '@mui/icons-material'
import _ from 'lodash'

type SearchTextFieldProps = {
  value?: string
  onSearch: (query: string) => void
} & Omit<TextFieldProps, 'onChange'>

export default function SearchTextField(props: SearchTextFieldProps) {
  const { value, onSearch, ...rest } = props
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (value || value === '') {
      setInputValue(value)
    }
  }, [value])

  const debouncedHandleChange = useCallback(
    _.debounce((query: string) => onSearch(query), 500),
    [onSearch],
  )

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const _value = event.target.value
    setInputValue(_value)
    debouncedHandleChange(_value)
  }

  return (
    <TextField
      value={inputValue}
      onChange={handleChange}
      autoFocus
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}
