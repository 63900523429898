import { Box, Typography, useTheme } from '@mui/material'
import CardContainer from './components/CardContainer'

interface ProdutoCardInfoProps {
  data: {
    codigo: string
    nome: string
    estoque: string
    precoUnitario: string
    produtoGrupoNome: string
  }
}

export default function ProdutoCardInfo(props: ProdutoCardInfoProps) {
  const { codigo, nome, estoque, precoUnitario, produtoGrupoNome } =
    props.data || {}
  const theme = useTheme()

  return (
    <CardContainer>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: 0.5 }}>
          {codigo} - {nome}
        </Typography>
        <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
          R${precoUnitario}
        </Typography>
        <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
          {estoque} em estoque
        </Typography>
        <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
          Grupo: {produtoGrupoNome}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.grey[600],
              fontWeight: 'bold',
              marginRight: 2,
            }}
          >
            R${precoUnitario}
          </Typography>
        </Box>
      </Box>
    </CardContainer>
  )
}
