import { memo } from 'react'
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material'
import FloatFooterCurrency from 'components/FloatFooter/components/FloatFooterCurrency'
import { NFConsultaModel } from 'hooks/queries/useNF'

export interface VendaCardInfoModel extends NFConsultaModel {
  dtLancamentoFormatada: string
  totalProdutoFormatado: string
  totalNFFormatado: string
}

interface VendaCardInfoProps {
  data: VendaCardInfoModel
  onClick: (nf: NFConsultaModel) => void
}

function VendaCardInfoComponent(props: VendaCardInfoProps) {
  const { data, onClick } = props

  const theme = useTheme()

  const statusMap: Record<string, string> = {
    0: theme.palette.warning.main,
    10: theme.palette.primary.main,
    40: theme.palette.success.main,
    90: theme.palette.error.main,
  }

  return (
    <Paper
      elevation={0}
      onClick={() => onClick(data)}
      sx={{
        border: `solid 1px ${theme.palette.grey[300]}`,
        marginTop: 1,
        ':hover': { cursor: 'pointer' },
        position: 'relative',
        ':after': {
          content: '""',
          position: 'absolute',
          width: '4px',
          height: '100%',
          backgroundColor: statusMap[data.status],
          top: 0,
          left: 0,
          bottom: 0,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingY: 1,
          paddingX: 2,
          borderBottom: `solid 1px ${theme.palette.grey[300]}`,
        }}
      >
        <Typography variant="body1" fontWeight="bold">
          {data?.statusDesc || ''}
        </Typography>
        <Typography variant="body2">{data.dtLancamentoFormatada}</Typography>
      </Box>

      <Stack
        sx={{
          paddingTop: 1,
          paddingX: 2,
          paddingBottom: 4,
          gap: 0.2,
          borderBottom: `solid 1px ${theme.palette.grey[300]}`,
        }}
      >
        <Typography variant="body1">
          {data?.pessoaCodigo || ''} - {data?.pessoaNome || ''}
        </Typography>
      </Stack>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingY: 1,
          paddingX: 2,
        }}
      >
        <Stack sx={{ gap: 0.5, alignItems: 'flex-end' }}>
          <FloatFooterCurrency
            title="Total Produto:"
            price={data?.totalProdutoFormatado}
          />
          <FloatFooterCurrency
            title="Total:"
            price={data?.totalNFFormatado}
            priceProps={{ variant: 'h4', fontWeight: 'bold' }}
          />
        </Stack>
      </Box>
    </Paper>
  )
}

export const VendaCardInfo = memo(VendaCardInfoComponent)
